<template>
<div>
    <section class="about">
      <div class="container">
        <div class="title">About Alexander</div>
        <div class="raw">
          <div>
            <div class="article">
              <p>Alexander could have become a really good biologist after finishing Trinity College in Dublin, where he moved from Moscow to grow wiser, but fortunately, in a dramatic twist of fate, he had broke his leg and moved to London instead.</p>
              <p>Alexander loved making short films about his Russian friends doing some silly and crazy stuff, which eventually got him accepted into London College of Communication where he completed his Bachelor Degree in Film and Video. But that wasn’t enough for him - <b>Alexander wanted people to call him Master, that prompted him to enter Central Saint Martins Drama Centre which he then graduated with a Master Degree of Arts in Directing for Screen.</b></p>
            </div>
          </div>
          <div>
            <div class="imgGroup imgGroup1">
              <div class="titleImg" style="background-image: url(./img/about/at1.jpg);" v-on:click="select" v-on:mouseover="hover" data-item="1"></div>
              <div class="titleImg double1" style="background-image: url(./img/about/at1.jpg);"></div>
              <div class="imgs">
                <div class="img img1" style="background-image: url(./img/about/g1a1.jpg);" v-on:click="select" v-on:mouseover="hover" data-item="2"></div>
                <div class="img img1 double2" style="background-image: url(./img/about/g1a1.jpg);"></div>
                <div class="img img2" style="background-image: url(./img/about/g1a2.jpg);" v-on:click="select" v-on:mouseover="hover" data-item="3"></div>
                <div class="img img2 double3" style="background-image: url(./img/about/g1a2.jpg);"></div>
                <div class="img img3" style="background-image: url(./img/about/g1a3.jpg);" v-on:click="select" v-on:mouseover="hover" data-item="4"></div>
                <div class="img img3 double4" style="background-image: url(./img/about/g1a3.jpg);"></div>
              </div>

            </div>
          </div>
          <div class="a2">
            <div class="article">
              <p>Alexander learned a hell of a lot about acting there - that gave him a really good technique in working with actors and also the ability to talk about Marlon Brando for hours.</p>
              <p>Alexander shoots Commercials, TV series, Documentaries, Music Videos and everything else that can be shot. The depth of his creativity may be compared only to the level of his involvement n the production process.</p>
            </div>
          </div>
          <div class="order3">
            <div class="imgGroup imgGroup2">
              <div class="titleImg" style="background-image: url(./img/about/at2.jpg);" v-on:click="select" v-on:mouseover="hover" data-item="1"></div>
              <div class="titleImg double1" style="background-image: url(./img/about/at2.jpg);"></div>
              <div class="imgs">
                <div class="img img1" style="background-image: url(./img/about/g2a1.jpg);" v-on:click="select" v-on:mouseover="hover" data-item="2"></div>
                <div class="img img1 double2" style="background-image: url(./img/about/g2a1.jpg);"></div>
                <div class="img img2" style="background-image: url(./img/about/g2a2.jpg);" v-on:click="select" v-on:mouseover="hover" data-item="3"></div>
                <div class="img img2 double3" style="background-image: url(./img/about/g2a2.jpg);"></div>
                <div class="img img3" style="background-image: url(./img/about/g2a3.jpg);" v-on:click="select" v-on:mouseover="hover" data-item="4"></div>
                <div class="img img3 double4" style="background-image: url(./img/about/g2a3.jpg);"></div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>
    <fe/>
  </div>
</template>
<script>
import footer1 from "../sections/footer.vue";

export default {
  components: {
    fe:footer1
  },
  name: 'About',
  data(){return {
  }},
  methods: {
    select:function (e) {
      let target = e.target;
      const imgs = document.querySelectorAll('section.about .titleImg, section.about .imgs .img');
      imgs.forEach((img)=>{img.classList.remove('selected');})
      let id = target.dataset.item;
      // target.classList.add('selected');
      const double = e.target.closest('.imgGroup').querySelector('.double'+id);
      double.classList.add('hovered');
      // e.target.closest('.imgGroup').querySelector('.double'+id).add('hovered');
    },
    hover: function (e) {
      const hovered = e.target.closest('.imgGroup').querySelector('.hovered');
      if(hovered) hovered.classList.remove('hovered');
      let id = e.target.dataset.item;
      const double = e.target.closest('.imgGroup').querySelector('.double'+id);
      double.classList.add('hovered');
    },
  },
}
</script>
