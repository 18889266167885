
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../routes/Home.vue'
import Works from '../routes/Works.vue'
import Work from '../routes/Work.vue'
import About from '../routes/About.vue'

import p404 from '../routes/404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/works',
    name: 'Works',
    component: Works
  },
  {
    path: '/Work/:id',
    name: 'Work',
    component: Work
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  { path: "*",name: '404', component: p404 }
]

const router = new VueRouter({
  mode: 'history',
  base: "/",//demo30/
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    return { x: 0, y: 0 }
  },
  
})
router.beforeEach((to, from, next) => {
  // if(to.path=='/' || to.path.includes('/mycard/')){
  //   document.querySelector('body').classList.add('main');
  //   document.querySelector('body').classList.remove('p404');
  // }else if(to.path=='/404' || to.name == '404'){
  //   document.querySelector('body').classList.add('p404');
  //   document.querySelector('body').classList.remove('main');
  // }else{
  //   document.querySelector('body').classList.remove('p404');
  //   document.querySelector('body').classList.remove('main');
  // }
  
  next();
})

export default router
