<template>
  <header>
    <div class="container">
      <div class="raw">
        <div class="LC">
          <a v-on:click="$router.push('/')" class="logo">
            <div class="logoDot"></div>
            <div class="logoText">ALEXANDER SOLOVIEV</div>
          </a>
        </div>
        <div class="RC">
          <a href="mailto:dasha@a-talentagency.com" target="_blank" class="mailButton">dasha@a-talentagency.com</a>
        </div>
      </div>
    </div>
  </header>
</template>
<script>

export default {
  name: 'appheader',
  data() {
    return {
    }
  },
  components: {
  },
  beforeCreate: async function () {
  },
  mounted: function () {
  },
  beforeDestroy: function () {
  },
  methods: {
    openMenu: function () {
      if (!document.querySelector('#menuOpen').classList.contains('showMenu')) {
        document.querySelector('#menuShadow').classList.add("showMenu");
        document.querySelector('#menuOpen').classList.add("showMenu");
        document.querySelector('#topMenu').classList.add("showMenu");
      } else this.closeMenu();
    },
    closeMenu: function () {
      document.querySelector('#menuShadow').classList.remove("showMenu");
      document.querySelector('#menuOpen').classList.remove("showMenu");
      document.querySelector('#topMenu').classList.remove("showMenu");
    }
  }
}

</script>