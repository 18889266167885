<template>
  <div>
    <div class="worksPage">
      <projects/>
    </div>
  </div>
</template>
<script>
import projects from "../sections/projects.vue";
// import footer from "../sections/footer.vue";

export default {
  components: {
    projects,
  },
  name: 'Works',
  data(){return {
  }},
  methods: {
  },
}
</script>
