<template>
  <section class="home" id="home" v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
    <div class="noise__container">
      <div class="noise"></div>
    </div>
    <mobB/>
    <div class="middle">
      <div class="pBgs">
        <div class="photoBg photoBg1"></div>
        <div class="photoBg photoBg2">
          <video preload="metadata" muted="muted" autoplay playsinline loop>
            <source src="../assets/video/SOLOVIEV_select.mp4" type="video/mp4">
          </video>
        </div>
        <div class="photoBg photoBg3">
          <video preload="metadata" muted="muted" autoplay playsinline loop>
            <source src="../assets/video/SOLOVIEV_commercial.mp4" type="video/mp4">
          </video>
        </div>
        <div class="photoBg photoBg4">
          <video preload="metadata" muted="muted" autoplay playsinline loop>
            <source src="../assets/video/SOLOVIEV_fiction.mp4" type="video/mp4">
          </video>
        </div>
        <div class="photoBg photoBg5">
          <video preload="metadata" muted="muted" autoplay playsinline loop>
            <source src="../assets/video/SOLOVIEV_music.mp4" type="video/mp4">
          </video>
        </div>
        <div class="photoBg photoBg6">
          <video preload="metadata" muted="muted" autoplay playsinline loop>
            <source src="../assets/video/SOLOVIEV_doc.mp4" type="video/mp4">
          </video>
        </div>
      </div>
      <div class="menu">
        <div class="container">
          <div class="menuItems">
            <div class="item item1" data-item="1">
              <div class="position">01</div>
              <a class="title" v-on:mouseover="hover" v-on:mouseleave="unHover" v-on:click="$router.push('/about')">ABOUT</a>
            </div>
            <div class="item item2" data-item="2">
              <div class="fictionAction">watch the video <img src="../assets/images/fictionIcon.svg"/></div>
              <div class="position">02</div>
              <a class="title" v-on:mouseover="hover" v-on:mouseleave="unHover" v-on:click="$router.push('/works#selected')">SELECTED</a>
            </div>
            <div class="item item3" data-item="3">
              <div class="fictionAction">watch the video <img src="../assets/images/fictionIcon.svg"/></div>
              <div class="position">03</div>
              <a class="title" v-on:mouseover="hover" v-on:mouseleave="unHover" v-on:click="$router.push('/works#commercials')">СOMMERCIALS</a>
            </div>
            <div class="item item4" data-item="4">
              <div class="fictionAction">watch the video <img src="../assets/images/fictionIcon.svg"/></div>
              <div class="position">04</div>
              <a class="title" v-on:mouseover="hover" v-on:mouseleave="unHover" v-on:click="$router.push('/works#fiction')">FICTION</a>
            </div>
            <div class="item item5" data-item="5">
              <div class="fictionAction">watch the video <img src="../assets/images/fictionIcon.svg"/></div>
              <div class="position">05</div>
              <a class="title" v-on:mouseover="hover" v-on:mouseleave="unHover" v-on:click="$router.push('/works#musicvideo')">MUSIC VIDEO</a>
            </div>
            <div class="item item6" data-item="6">
              <div class="fictionAction">watch the video <img src="../assets/images/fictionIcon.svg"/></div>
              <div class="position">06</div>
              <a class="title" v-on:mouseover="hover" v-on:mouseleave="unHover" v-on:click="$router.push('/works#documentary')">DOCUMENTARY</a>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <!-- <div class="triangle"></div> -->
        <!-- <div class="showrealButton_box_of_box">
          <div class="showrealButton_box" :style="'transform:matrix(1, 0, 0, 1, '+transform.x+','+transform.y+');'" data-item="7">
            <a class="showrealButton" v-on:mouseover="hover" v-on:mouseleave="unHover"></a>
          </div>
        </div> -->
        <div class="showreal_box" :style="'transform:matrix(1, 0, 0, 1, '+transform.x+','+transform.y+');'">
          <div class="showreal_bg_big"></div>
          <div class="showreal_bg_small"></div>
          <div class="showreal_button" v-on:click="showrealOpen">
            <div class="showreal_button_bg"></div>
            <div class="showreal_button_play"></div>
          </div>

        </div>
      </div>
    </div>
    <div class="bigRedCircle"></div>
    <div class="showrealBox">
      <div class="showreal">
        <div class="videoBox">
          <iframe src="https://player.vimeo.com/video/773291847?h=4baf0dd35b&color=74a1b1&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        </div>
        <!-- <div class="screensaver"></div> -->
        <!-- <div class="play_box" v-on:click="play">
          <div class="play">
            <div class="cIcon"></div>
            <div class="cText">PLAY</div>
          </div>
        </div> -->
        <div class="close" v-on:click="closeShowreal">
          <div class="cText">CLOSE</div>
          <div class="cIcon"></div>
        </div>
      </div>
      <mobB/>
    </div>
  </section>
</template>
<script>
import mobB from "../sections/mobB.vue";

export default {
  name: 'home',
  data(){return{
    mouse: {
      x: undefined,
      y: undefined
    },
    showrealButton: {
      x: undefined,
      y: undefined
    },
    window: {
      w: undefined,
      h: undefined
    },
    transform:{
      x: 0,
      y: 0
    },
    activeId:1,
    boardLeft:0,
    boardLeftPermanent:0,
  }},
  components: {
    mobB
  },
  beforeMount : async function () {
    window.addEventListener("mousemove", this.eventListener);
    window.addEventListener("resize", this.onResize);
  },
  mounted : function () {
    this.onResize();
    if(window.innerWidth>=768)return;
    const home = document.querySelector('section.home');
    home.classList.add('hideItems');
    home.classList.add('hoverItem1');

  },
  beforeDestroy:function () {
    window.removeEventListener("mousemove", this.eventListener);
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    eventListener:function (e) {
      this.mouse.x = e.clientX;
      this.mouse.y = e.clientY;
      this.getTheta();
    },
    onResize:function () {
      // let showrealButton_box = document.querySelector('.showrealButton_box_of_box');
      // let showrealButton = document.querySelector('.showrealButton');
      
      // this.showrealButton.x = Math.round(showrealButton_box.getBoundingClientRect().left+(showrealButton.offsetWidth/2));
      // this.showrealButton.y = Math.round(showrealButton_box.getBoundingClientRect().top+(showrealButton.offsetHeight/2));
      // console.log(this.showrealButton.x)
      
      this.showrealButton.x = Math.round(window.innerWidth/2);
      this.showrealButton.y = Math.round(window.innerHeight/2);

      this.window.w = window.innerWidth;
      this.window.h = window.innerHeight;
      this.getTheta();
    },
    getTheta:function () {
      if(!this.showrealButton.x||!this.showrealButton.y||!this.mouse.x||!this.mouse.y)return;
        let dx = this.mouse.x - this.showrealButton.x;
        let dy = this.mouse.y - this.showrealButton.y;

        let r = Math.sqrt(Math.pow(dx,2)+Math.pow(dy,2));
        const rPr = r/100*4;
        const maxLuft=this.window.w-this.showrealButton.x*2;
        const leftPr = this.mouse.x/(this.window.w/100);
        const bias = maxLuft*(leftPr/100);
        // this.transform.x = bias;

        let b = Math.round(this.window.w*0.044642857)-2;
        r = b/(1.540357363)*100;
        let y0=b-r;
        let x0=0;
        let xDef=Math.sqrt(r**2-(0-y0)**2)+x0;
        let x=(leftPr)*(xDef*2/100)-xDef;
        let y=Math.sqrt(r**2-(x-x0)**2)+y0;
        this.transform.x = rPr*Math.cos(Math.atan2(dy, dx));
        // this.transform.y = -30 -y + rPr*Math.sin(Math.atan2(dy, dx));
        this.transform.y = rPr*Math.sin(Math.atan2(dy, dx));
    },
    hover: function (e) {
      const home = document.querySelector('section.home');
      let id = e.target.parentElement.dataset.item;
      home.classList.add('hideItems');
      home.classList.add('hoverItem'+id);
    },
    unHover: function (e) {
      const home = document.querySelector('section.home');
      let id = e.target.parentElement.dataset.item;
      home.classList.remove('hideItems');
      home.classList.remove('hoverItem'+id);
    },
    showrealOpen: function () {
      const header = document.querySelector('header .logoDot');
      const home = document.querySelector('section.home');
      const showreal = document.querySelector('section.home .showreal');
      showreal.classList.remove('play');
      showreal.classList.remove('hideScreensaver');
      home.classList.add('showrealOpen');
      header.classList.add('white');
      const iframe = document.querySelector('section.home .showreal iframe');
      const player = new Vimeo.Player(iframe);
      player.play();

    },
    closeShowreal: function () {
      const header = document.querySelector('header .logoDot');
      const home = document.querySelector('section.home');
      const iframe = document.querySelector('section.home .showreal iframe');
      const player = new Vimeo.Player(iframe);
      home.classList.remove('showrealOpen');
      header.classList.remove('white');
      player.pause();
      // const video =document.querySelector('section.home .showreal video');
      // video.pause();
      // video.currentTime = 0;

    },
    play: function () {
      // const showreal = document.querySelector('section.home .showreal');
      // const video =document.querySelector('section.home .showreal video');
      // showreal.classList.add('play');
      // showreal.classList.add('hideScreensaver');
      // video.play();
    },
    pause: function () {
      // const showreal = document.querySelector('section.home .showreal');
      // const video =document.querySelector('section.home .showreal video');
      // showreal.classList.remove('play');
      // video.pause();
    },
    swipeLeft(){
      this.sliderFn(0)
    },
    swipeRight(){
      this.sliderFn(1)
    },
    sliderFn(direction){
      if(window.innerWidth>=768)return;
      const board =document.querySelector('.menu');
      const canvas =document.querySelector('.menuItems');
      const firstItem =document.querySelector('.menuItems .item'+this.activeId);
      // console.log('.menuItems .item'+this.activeId);
      if(direction==0){
        const next = firstItem.nextSibling;
        if(!next)return;
        var style = next.currentStyle || window.getComputedStyle(next);
        var targetW=firstItem.offsetWidth+parseInt(style.marginLeft,10);
        this.boardLeftPermanent=this.boardLeft-targetW;
        this.activeId++;
      }else if(direction==1){
        const prev = firstItem.previousSibling;
        if(!prev)return;
        var style = firstItem.currentStyle || window.getComputedStyle(firstItem);
        var targetW=prev.offsetWidth+parseInt(style.marginLeft,10);
        this.boardLeftPermanent=this.boardLeft+targetW;
        this.activeId--;
      }
      if(this.boardLeftPermanent>0)this.boardLeftPermanent=0;

      const home = document.querySelector('section.home');
      home.classList.add('hideItems');
      for(let i=1; i<7; i++){
        home.classList.remove('hoverItem'+i);
      }
      home.classList.add('hoverItem'+this.activeId);
      this.boardLeft=this.boardLeftPermanent;
      board.style.transform='translate3d('+this.boardLeft+'px, 0px, 0px)';
    },
  }
}

</script>