<template>
  <footer>
    <div class="container">
      <div class="raw">
        <div>
          <div class="lets"></div>
        </div>
        <div>
          <div class="form">
            <div class="col"><input type="text" placeholder="NAME*"  v-model="name" ></div>
            <div class="col"><input type="text" placeholder="EMAIL*" v-model="email"></div>
            <div class="col"><input type="text" placeholder="SUBJECT" v-model="subject"></div>
            <div class="col"><textarea placeholder="MESSAGE" v-model="message"></textarea></div>
            <div class="col">
              <a class="button" v-on:click="send">SEND</a>
            </div>
          </div>
            
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
const emailRegExp=/^([^-<>()\[\]\\.,;:\s@"']+[^<>()\[\]\\,;:\s@"']*)@([^<>()\[\]\\,;:\s@"'\*№]+)\.([^-_<>()\[\]\\.,;:\s@"'#$&{}\^\*№~]+)$/

export default {
  name: 'footer1',
  data(){return{
    name:'',
    email:'',
    subject:'',
    message:'',
  }},
  components: {
  },
  methods: {
    send:async function () {
      if(this.name.length==0)return alert('Filed "NAME" is required')
      if(this.email.length==0)return alert('Filed "EMAIL" is required')
      if(emailRegExp.test(this.email)==false)return alert('Filed "EMAIL" is incorrect')
      await fetch('/_mailto/send.php?name='+this.name+'&email='+this.email+'&subject='+this.subject+'&message='+this.message, {mode: 'cors'});
      alert('Your message has been sent');
      this.name='';
      this.email='';
      this.subject='';
      this.message='';

    },
  },
}

</script>