<template>
  <section class="projects">
    <div class="container">
    <div class="title">Works</div>
    </div>
    <div class="tags">
      <ul class="tagsList">
        <li v-for="tag in tags" :key="tag.key">
          <a v-on:click="$router.push('/works'+(tag.key!='all'?'#'+tag.key:''));selectedTag=tag.key;" :class="{'selected': tag.key==selectedTag}">{{tag.title}}</a>
        </li>
      </ul>

    </div>
    <div class="container">
      <div class="cards">
        <a v-for="project in projects" :key="project.id"  :class="{'card':true,'light':project.light===true}" v-if="project.tags.includes(selectedTag)" v-on:click="$router.push('/work/'+project.id);">
          <div class="box" :style="'background-image: url('+project.image+');'">
            <div class="title" v-html="project.title"></div>
            <div class="play">
              <div class="cIcon"></div>
              <div class="cText">PLAY</div>
            </div>
          </div>
        </a>

      </div>
      
    </div>
  </section>
</template>
<script>
import tags from '../data/tags';
import projects from '../data/projects';

export default {
  name: 'projects',
  data(){return{
    selectedTag: window.location.hash.length>0 ? window.location.hash.substring(1) : 'all',
    tags:tags,
    projects:projects,
  }},
  components: {
  },
  beforeCreate : async function () {
  },
  mounted : function () {
  },
  beforeDestroy:function () {
  },
  methods: {
  }
}

</script>