<template>
  <section class="work" :key="componentKey">
    <div class="works">
      <a class="button" v-on:click="$router.push('/works');">Works</a>
    </div>
    <div class="projectHeader" v-if="project">
      <div class="videoBox play" v-if="project.type=='media'">
        <div :style="'padding:'+(project.padding||'56.25%')+' 0 0 0;position:relative;'"><iframe :src="project.media+'?autoplay=1&title=0&byline=0&portrait=0'" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>

        <!-- <video preload="metadata" autoplay playsinline v-on:click="play" v-on:pause="pause">
          <source :src="project.media" type="video/mp4">
        </video>
        <div class="play_box" v-on:click="play">
          <div class="play">
            <div class="cIcon"></div>
            <div class="cText">PLAY</div>
          </div>
        </div> -->
      </div>
      <div class="images">
        <img v-if="project.type=='images'" v-for="image in project.images" :key="image" :src="image">
      </div>
    </div>
    <div class="panel">
      <div class="container">
        <div class="raw">
          <div><a class="button" v-on:click="$router.push('/works');">Works</a></div>
          <div class="title" v-html="project.title"></div>
          <div class="b2">
            <a class="button" v-on:click="$router.push('/work/'+projectBack.id);">Back</a>
            <a class="button" v-on:click="$router.push('/work/'+projectNext.id);">Next</a>
          </div>
        </div>
      </div>
    </div>
    <mobB/>
  </section>
</template>
<script>
import tags from '../data/tags';
import projects from '../data/projects';
import mobB from "../sections/mobB.vue";

export default {
  name: 'Work',
  data(){return{
    tags:tags,
    projects:projects,
    project:projects[this.$route.params.id-1],
    componentKey: 0,
    projectBack:projects[this.$route.params.id - 2] || projects[projects.length-1],
    projectNext:projects[this.$route.params.id] || projects[0],
  }},
  components: {
    mobB
  },
  beforeCreate : async function () {
    if(!this.$route.params.id) return this.$router.push('/works');
  },
  created: function() {
    this.$watch(
      () => this.$route.params,
      (to, from) => {
        this.project = projects[to.id-1];
        this.projectBack = projects[to.id - 2] || projects[projects.length-1];
        this.projectNext = projects[to.id] || projects[0];
        this.componentKey++;
      }
    )
  },
  methods: {
    play: function () {
      const videoBox = document.querySelector('section.work .videoBox');
      const video =document.querySelector('section.work .videoBox video');
      if(videoBox.classList.contains('play')) this.pause();
      else{
        videoBox.classList.add('play');
        video.play();
      }
    },
    pause: function () {
      const videoBox = document.querySelector('section.work .videoBox');
      const video =document.querySelector('section.work .videoBox video');
      videoBox.classList.remove('play');
      video.pause();
    }
  }
}

</script>